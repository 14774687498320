import React from "react";
import {
  Navigate,
} from "react-router-dom";

const MaterialAdd = React.lazy(() => import("../../../../../components/materials/components/add/page"));
const MaterialList = React.lazy(() => import("../../../../../components/materials/components/list/page"));

export const materialsRoute = [
  {
    path: 'list',
    element: <MaterialList />,
  },
  {
    path: 'add',
    element: <MaterialAdd />,
  },
  {
    path: 'edit',
    element: <Navigate to="/store/materials/add" />
  },
  {
    path: 'edit/:id',
    element: <MaterialAdd />
  },
  {
    path: '',
    element: <Navigate replace to="/store/materials/list" />
  },
]