import React from "react";
import {
  Navigate,
} from "react-router-dom";


const LunchAdd = React.lazy(() => import("../../../components/lunch/components/add/page"));
const LunchList = React.lazy(() => import("../../../components/lunch/components/list/page"));
const TicketsList = React.lazy(() => import("../../../components/lunch/components/tickets/page"));
const LunchRequest = React.lazy(() => import("../../../components/lunch/components/request/page"));
const TicketAttention = React.lazy(() => import("../../../components/lunch/components/attention/page"));
const TicketsReport = React.lazy(() => import("../../../components/lunch/components/reports/page"));
const TicketsAdvanced = React.lazy(() => import("../../../components/lunch/components/advanced/page"));

export const lunchRoutes = [
  {
    path: 'add',
    element: <LunchAdd />,
  },
  {
    path: 'attention',
    element: <TicketAttention />,
  },
  {
    path: 'edit',
    element: <LunchAdd />,
  },
  {
    path: 'list',
    element: <LunchList />,
  },
  {
    path: 'advanced',
    element: <TicketsAdvanced />,
  },
  {
    path: 'request',
    element: <LunchRequest />,
  },
  {
    path: 'tickets',
    element: <TicketsList />,
  },
  {
    path: 'report',
    element: <TicketsReport />,
  },
  {
    path: 'ticket-edit/:id',
    element: <LunchRequest />,
  },
  {
    path: 'edit/:id',
    element: <LunchAdd />
  },
  {
    path: '',
    element: <Navigate replace to="/lunchs/tickets" />
  },
]