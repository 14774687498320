import React from "react";
import { Outlet } from "react-router-dom";
import {
    Grid,
    Paper,
} from '@mui/material';

import '../css/login.css';

// import CenefaLogin from "../images/cenefa-cg.png";
// import FondoLogin from "../images/fondo-min.jpg";

const FondoLogin = require("../images/fondo-min.jpg");
const CenefaLogin = require("../images/cenefa-login.png");


export const Outdoor = () => {
   


    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="login-root"
            style={{
                backgroundImage: `url(${FondoLogin})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <Grid item xl={3} lg={4} md={4} sm={8} xs={12} >
                <Paper elevation={3}>
                    <Grid container justifyContent='center' className="login-sub-root">
                        <Grid item xs={12} className="login-cenefa">
                            <img src={CenefaLogin} width='100.12%' height='100%' alt='CG' />
                        </Grid>
                       <Grid item>
                            <Outlet/>
                       </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}