import React from "react";
import { 
  GenericPage
} from "../../../../../pages";
import { materialsRoute } from "./store-materials.route";
import { suppliersRoute } from "./store-suppliers.route";


export const storeRoute = [
  {
    path: 'materials',
    element: <GenericPage />,
    children: materialsRoute,
  },
  {
    path: 'suppliers',
    element: <GenericPage />,
    children: suppliersRoute,
  },
//   {
//     path: '',
//     element: <Navigate replace to="/stock/materials" />
//   },
]