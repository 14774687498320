import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { proformChangeStatusGet } from "../../libs/apis/proforms";
import Swal from "sweetalert2";

export const ProformApproved = () => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        handleValidate();
    }, [])




    const handleValidate = async () => {
        try {
            const { data } = await proformChangeStatusGet({ path: "approved-cg", id: Number(id!) });
            Swal.fire({
                icon: 'success',
                title: `Proforma N°:${id!.toString().padStart(6, '0')}`,
                confirmButtonColor: '#0E3B5F',
                html: `<strong>${data}</strong>`,
            }).then(() => {
                window.close();

            });
        } catch (err: any) {
            console.log(err);
            enqueueSnackbar(err.toString(), { variant: 'success' });
        }

    }


    return (
        <CircularProgress size={30} />
    )
}