import { useQuery } from "@tanstack/react-query";
import { typeWorkApi } from "./axios";
import { 
    DataI,
    TypeWorkI, 
    PropsI, 
    ServiceResponseI 
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



const getTypeWork = async ({
    pagination, 
    sorting, 
    globalFilter
}:PropsI):Promise<ServiceResponseI<DataI<TypeWorkI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination?.pageIndex ?? 0 + 1).toString());
        params.append('limit', pagination?.pageSize.toString() ?? '15');
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        
        //headers: { 'Authorization': validateToken
        const { data } = await typeWorkApi.get<ServiceResponseI<DataI<TypeWorkI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }

    
}

export const typeWorkPost = async(getForms:any)=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');
        await typeWorkApi.post("/", getForms);
        return 'Agregado con Éxito';
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const typeWorkByParentId = async (id:number):Promise<ServiceResponseI<TypeWorkI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await typeWorkApi.get<ServiceResponseI<TypeWorkI[]>>(`/${id}`);
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllParentsTypeWork = async ():Promise<ServiceResponseI<TypeWorkI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await typeWorkApi.get<ServiceResponseI<TypeWorkI[]>>(`/`);
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}




export const useTypeWork = ({
    sorting,
    pagination,
    globalFilter
}:PropsI)=>{

    
    const typeWorkQuery = useQuery(
        ["typeWork", { pagination, sorting, globalFilter }],
        ()=>getTypeWork({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );



  


    return {
        typeWorkQuery,
    };
}