import React from "react";
import {
  Navigate,
} from "react-router-dom";


const WareHouseAdd = React.lazy(() => import("../../../components/warehouse-client-v2/components/add/page"));
const WareHouseList = React.lazy(() => import("../../../components/warehouse-client-v2/components/list/page"));
const WareHouseCardsParent = React.lazy(() => import("../../../components/warehouse-client-v2/components/add/cards-parent"));
const WareHouseCardsChild = React.lazy(() => import("../../../components/warehouse-client-v2/components/add/cards-child"));
const WareHouseCategories = React.lazy(() => import("../../../components/warehouse-client-v2/components/add/categories"));


export const salesWareHouseCategoryItemsRoutes = [
  {
    path: '',
    element: <WareHouseCardsParent/>
  },
  {
    path: 'children/:id',
    element: <WareHouseCardsChild />
  },

];


export const salesWareHouseCategoryRoutes = [
  {
    path: '',
    element: <WareHouseCategories/>
  },
  {
    path: 'items/:id',
    children:salesWareHouseCategoryItemsRoutes
  },

];

export const wareHouseRoutes = [
    {
      path: 'list',
      element: <WareHouseList />,
    },
    {
      path: 'add',
      element: <WareHouseAdd />,
    },
    {
      path: 'home',
      element: <WareHouseAdd />,
    },
    {
      path: 'edit',
      element: <Navigate to="/warehouse/add" />
    },
    {
      path: 'edit/:id',
      element: <WareHouseAdd />
    },
    // {
    //   path: 'items/:id',
    //   element: <WareHouseCardsParent />
    // },
    // {
    //   path: 'items/:id',
    //   element: <WareHouseCategories />
    // },
    {
      path: 'categories/:id',
      children: salesWareHouseCategoryRoutes,
      // element: <WareHouseCategories />
    },
    {
      path: '',
      element: <Navigate replace to="/warehouse/home" />
    },
  ];

