import { useQuery } from "@tanstack/react-query";
import { profileModuleOptionApi } from "./axios";
import {
    // DataI,
    // MigrationsI,
    // PropsI,
    ServiceResponseI,
    ModuleOptionI,
    PresentationI,
    OptionI,
    ModuleI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



export const getAllModulesByProfileId = async (profileId:string):Promise<ServiceResponseI<ModuleI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('profile', profileId);

        const { data } = await profileModuleOptionApi.get<ServiceResponseI<ModuleI[]>>(
            `/all-modules-by-profile-id`, 
            {    params,
                headers: { 'Authorization': validateToken }
            });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            LogoutSystem();
        }

        if (obj?.status === 400) {
            LogoutSystem();
        }

        return "Error" as any;

        // throw new Error((obj?.data as Error).message)
    }
}


export const profileModulePost = async<T>(getForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await profileModuleOptionApi.post("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const profileModulePut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await profileModuleOptionApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const profileModulebyId = async (id: number): Promise<ServiceResponseI<ModuleOptionI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await profileModuleOptionApi.get<ServiceResponseI<ModuleOptionI[]>>(`/all-by-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        return "Error" as any;
        // throw new Error((obj?.data as Error).message)
    }
}

