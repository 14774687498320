import React from "react";
import {
    Navigate,
} from "react-router-dom";

import { GenericPage } from "../../../../../pages";
import {
    salesProformsRoutes
} from "./sales-proforms.route";
import { salesWareHouseRoutes } from "./sales-warehouse.route";
import { salesProforms2Routes } from "./sales-proforms2.route";

export const salesRoute = [
    {
        path: 'proforms2',
        element: <GenericPage />,
        children: salesProforms2Routes
    },
    {
        path: 'proforms',
        element: <GenericPage />,
        children: salesProformsRoutes
    },

    {
        path: 'warehouse',
        element: <GenericPage />,
        children: salesWareHouseRoutes
    },
    {
        path: '',
        element: <Navigate replace to="/sales/proforms/list" />
    },
];
