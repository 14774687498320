import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
} from '@mui/material';

import {
    CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import { useRouterStore } from '../../../libs/stores';

export const DialogCloseSession = ({ open, handleClose }) => {

    const navigate = useNavigate();
    const reset = useRouterStore((state)=> state.reset)
    const [ isClosing, setClosing ]   = useState(false);
    const [ isContinuo, setContinuo ] = useState(false);

    useEffect(()=>{
        if(isContinuo){
            handleCloseSession();
        }
    },[isContinuo]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCloseSession = () => {
        reset();
        localStorage.removeItem(process.env.REACT_APP_TOKEN);
        localStorage.removeItem(process.env.REACT_APP_TOKEN_OPTIONS);
        setTimeout(()=>{
            navigate('/', { replace:true})
            setClosing(false);
            handleClose();
        },2000)
    };

    const handleContinuo = ()=>{
        setClosing(true);
        setTimeout(()=>{
            setContinuo(true);
        },1000)
    }



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sm'
            aria-labelledby="form-dialog-title" >
            <DialogTitle 
                id="form-dialog-title" 
                sx={{ textAlign: 'center', fontSize:18, fontWeight:'bolder' }}>
                Seguro que desea Salir?
                </DialogTitle>
            <DialogActions 
                sx={{display:"flex", justifyContent:"space-around"}}>
                <Button
                    onClick={handleContinuo}
                    color="primary"
                    variant="contained"
                    size='medium'
                    endIcon={isClosing
                        ? <CircularProgress size={20} color='error' />
                        : <CheckCircleIcon />
                    }>
                    Continuar
                </Button>
                <Button
                    onClick={handleClose}
                    size='medium'
                    color="error"
                    variant="text"
                    >
                    Cancelar
                </Button>

            </DialogActions>
        </Dialog>
    )
}