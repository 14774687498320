import { useQuery } from "@tanstack/react-query";
import { usersClientsMarcasApi } from "./axios";
import { ServiceResponseI, UsersClientsMarcasI } from "../../interfaces";
import { AxiosError } from "axios";
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';

const getUsersClientsMarcas = async (urlConcat:string | null) => {
    
    if(urlConcat){
        const { data } = await usersClientsMarcasApi.get(urlConcat);
        return data;
    }

    const { data } = await usersClientsMarcasApi.get(''); 
    return data;
    
}

export const getAllbyUserClientId = async (userId:number, clientId:number ):Promise<ServiceResponseI<UsersClientsMarcasI[]>>=>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();
        params.append('client', clientId.toString());
        params.append('user', userId.toString());
        const { data } = await usersClientsMarcasApi.get<ServiceResponseI<UsersClientsMarcasI[]>>('/', { params });
        return data;
    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        
    }
}

export const useUsersClientsMarcas = (urlConcat:string | null)=>{

    const usersClientsMarcasQuery = useQuery(
        ["users-clients-marcas"],
        ()=>getUsersClientsMarcas(urlConcat),
        {
            refetchOnWindowFocus:false
        }
    );


    return {
        usersClientsMarcasQuery
    };
}