import React from "react";
import {
    Navigate,
} from "react-router-dom";

const LineListComponent = React.lazy(() => import("../../../../../components/line/components/list/page"));
const LineAddComponent = React.lazy(() => import("../../../../../components/line/components/add/page"));

export const lineRoute = [
    {
        path: 'list',
        element: <LineListComponent />
    },
    {
        path: 'add',
        element: <LineAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/lines/add" />
    },
    {
        path: 'edit/:id',
        element: <LineAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/lines/list" />
    },
];