import { number } from "prop-types";
import { ModuleI, OptionI } from "../../interfaces";
import { getAllModulesByProfileId } from "../../apis";
import { create,  } from "zustand";
import { persist, createJSONStorage,  } from 'zustand/middleware'
import { reset } from "module-alias";

type State = {
    options: OptionI[];
    modules: ModuleI[];
    profileId:string;
    clientId:number|null;
    avatar:string;
    cloneCatalogue:number|null;
}

type Actions = {
    setProfileId: (profileId: string) => void;
    setClientId: (clientId: number) => void;
    setOptions: (options: OptionI[]) => void;
    setModules: (modules: ModuleI[]) => void;
    setAvatar:(avatar:string)=>void;
    reset: () => void;
    resetOptions: () => void;
    resetModules: () => void;
    getModulesFromApi:()=>void;
    setCloneCatalogue:(catalogue:State['cloneCatalogue'])=>void;
}

const initialState: State = {
    options: [],
    modules: [],
    profileId:"",
    avatar:"",
    cloneCatalogue:null,
    clientId:null,
}


export const useRouterStore = create<State & Actions>()(
    persist(
        (set, get) => ({
            options: [],
            modules: [],
            profileId:"",
            avatar:"",
            cloneCatalogue:null,
            clientId:null,
            reset: () => set(initialState),
            resetOptions: () => set((state)=>({options:[]})),
            resetModules: () => set((state)=>({modules:[]})),
            getModulesFromApi:async()=>{
                const token = localStorage.getItem(process.env.REACT_APP_TOKEN!)

                if(!token){
                    localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
                    get().reset();
                    return;
                }
                if(!get().profileId){
                    localStorage.removeItem(process.env.REACT_APP_TOKEN_ROUTER!);
                    get().reset();
                     return;
                }

                const { data: dataModules } = await getAllModulesByProfileId(get().profileId!);
                get().setModules(dataModules!);
            },
            setOptions: (options) => set((state) => {
                get().resetOptions();
                return { options }
            }), 
            setModules: (modules) => set((state) => ({modules})),
            setProfileId: (profileId) => set((state) => ({ profileId })),
            setClientId: (clientId) => set((state) => ({ clientId })),
            setAvatar: (avatar) => set((state) => ({ avatar })),
            setCloneCatalogue:(cloneCatalogue)=>set((state)=>({ cloneCatalogue }))
        }),{name:process.env.REACT_APP_TOKEN_ROUTER!}
    )
);