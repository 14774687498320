import { useQuery } from "@tanstack/react-query";
import { proformsApi } from "./axios";
import {
    DataI,
    ListProformsI,
    PropsI,
    ServiceResponseI,
    GetProformsI,
    ProformApiPropsI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



const getProforms = async ({
    pagination,
    sorting,
    globalFilter,
    status,
}: ProformApiPropsI): Promise<ServiceResponseI<DataI<ListProformsI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        if(status)params.append('status', status.toString());

        //headers: { 'Authorization': validateToken
        const { data } = await proformsApi.get<ServiceResponseI<DataI<ListProformsI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }


}

export const proformsPost = async<T>(getForms: T): Promise<ServiceResponseI<number>>=> {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.post("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const proformsPut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const proformsById = async (id: number): Promise<ServiceResponseI<GetProformsI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.get<ServiceResponseI<GetProformsI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const createPdfById = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.get<ServiceResponseI<string>>(`/create-pdf/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const approvedByClient = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.get<ServiceResponseI<string>>(`/approved-client/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const sendToAb = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.get<ServiceResponseI<string>>(`/send-to-ab/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const proformChangeStatusPost = async ({ path, id, message }: { path: string, id: number, message:string }): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.post<ServiceResponseI<string>>(`${path}/${id}`, {message}, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        console.log(err);
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const proformChangeStatusGet = async ({ path, id }: { path: string, id: number }): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.get<ServiceResponseI<string>>(`/${path}/${id}`, { headers: { 'Authorization': validateToken } });
        return data; 
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const authorizeProform = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await proformsApi.get<ServiceResponseI<string>>(`/authorize-proform/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}




export const useProforms = ({
    sorting,
    pagination,
    globalFilter,
    status,
}: ProformApiPropsI) => {


    const proformsQuery = useQuery(
        ["proforms", { pagination, sorting, globalFilter, status }],
        () => getProforms({ pagination, sorting, globalFilter, status }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );






    return {
        proformsQuery,
    };
}