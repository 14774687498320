import { useQuery } from "@tanstack/react-query";
import { usersApi } from "./axios";
import { 
    DataI,
    ServiceResponseI, 
    UserI,
    UsersLoginI,
    UsersLoginResponseI,
    UserPropsI,
} from "../../interfaces";
import { TYPE_USER_EJECUTIVA_CODE } from "../../constants";
import { AxiosError } from "axios";
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';


const getAllUsers = async ({
    pagination, 
    sorting, 
    globalFilter,
    area,
    type
}:UserPropsI):Promise<ServiceResponseI<DataI<UserI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));
        area && params.append('area', area.toString());
        type && params.append('type', type.toString());
        
        //headers: { 'Authorization': validateToken
        const { data } = await usersApi.get<ServiceResponseI<DataI<UserI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
        
    }
}

export const usersGetbyToken = async () => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await usersApi.get<ServiceResponseI<UserI>>('by-token', { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const getUsersExecutive = async ()=>{
    try{

        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('code', TYPE_USER_EJECUTIVA_CODE);

        const { data } = await usersApi.get<ServiceResponseI<UserI[]>>(`get-all-by-code`, { params, headers: { 'Authorization': validateToken } });
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }
}

export const usersLoginPost = async (getForms:UsersLoginI): Promise<ServiceResponseI<UsersLoginResponseI>> => {
    try {
        
        const { data } = await usersApi.post<ServiceResponseI<UsersLoginResponseI>>("/login", getForms, );
        
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const userPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await usersApi.post("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const userPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await usersApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const userPutChangePassword = async<T>( updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await usersApi.put(`/change-password`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const userDelete = async(id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await usersApi.delete(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const userById = async (id: number): Promise<ServiceResponseI<UserI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await usersApi.get<ServiceResponseI<UserI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const userResetPassword = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await usersApi.get<ServiceResponseI<string>>(`/reset-password/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useUsers = ({
    sorting,
    pagination,
    globalFilter,
    area,
    type
}:UserPropsI) => {

    const usersQuery = useQuery(
        ["users", { pagination, sorting, globalFilter, area, type  }],
        () => getAllUsers({pagination, sorting, globalFilter, area, type }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData:true,
        }
    );


    return {
        usersQuery
    };
}