export const KEY_CRYPTO: string = process.env.REACT_APP_KEY_CRYPTO!;
export const IV_CRYPTO: string = process.env.REACT_APP_IV_CRYPTO!;
export const CRYPTO_DATA: string = process.env.REACT_APP_CRYPTO_DATA!;
export const TYPE_MATERIAL_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_CODE!;
export const CATALOGUE_STATUS_CLIENT_CODE: string = process.env.REACT_APP_CATALOGUE_STATUS_CLIENT_CODE!;
export const TYPE_IDENTIFICATION_CODE: string = process.env.REACT_APP_TYPE_IDENTIFICATION_CODE!;
export const TYPE_MATERIAL_LINE_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_LINE_CODE!;
export const TYPE_PAYMENT_CODE: string = process.env.REACT_APP_TYPE_PAYMENT_CODE!; 
export const METHOD_PAYMENT_CODE: string = process.env.REACT_APP_METHOD_PAYMENT_CODE!;
export const TYPE_MATERIAL_SUPPLIER_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_SUPPLIER_CODE!;
export const TYPE_MATERIAL_GROUP_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_GROUP_CODE!;
export const TYPE_MATERIAL_MATERIAL_CODE: string = process.env.REACT_APP_TYPE_MATERIAL_MATERIAL_CODE!;
export const CATALOGUE_MATERIAL_CATEGORY_CODE: string = process.env.REACT_APP_CATALOGUE_MATERIAL_CATEGORY_CODE!;
export const CATALOGUE_MATERIAL_PRESENTATION_CODE: string = process.env.REACT_APP_CATALOGUE_MATERIAL_PRESENTATION_CODE!;
export const CATALOGUE_MATERIAL_COLOR_CODE: string = process.env.REACT_APP_CATALOGUE_MATERIAL_COLOR_CODE!;
export const CATALOGUE_TYPE_CONTACT_CLIENT_CODE: string = process.env.REACT_APP_CATALOGUE_TYPE_CONTACT_CLIENT_CODE!;
export const TYPE_ACCOUNT_BANK_CODE: string = process.env.REACT_APP_TYPE_ACCOUNT_BANK_CODE!;
export const CONTACT_TYPES_MAIL_CODE: string = process.env.REACT_APP_CONTACT_TYPES_MAIL_CODE!;
export const CONTACT_TYPES_PHONE_CODE: string = process.env.REACT_APP_CONTACT_TYPES_PHONE_CODE!;
export const CC_TYPE_RETENCION_CODE: string = process.env.REACT_APP_CC_TYPE_RETENCION_CODE!;
export const CC_TYPE_FACTURA_CODE: string = process.env.REACT_APP_CC_TYPE_FACTURA_CODE!;
export const CC_TYPE_COBRANZAS_CODE: string = process.env.REACT_APP_CC_TYPE_COBRANZAS_CODE!;
export const COLLECTION_TYPES_CODE: string = process.env.REACT_APP_COLLECTION_TYPES_CODE!;
export const WEEK_DAYS_CODE: string = process.env.REACT_APP_WEEK_DAYS_CODE!;
export const UNITS_MEASUREMENT_CODE: string = process.env.REACT_APP_UNITS_MEASUREMENT_CODE!;
export const CHARACTERISTICS_MATERIALS_CODE: string = process.env.REACT_APP_CHARACTERISTICS_MATERIALS_CODE!;
export const PINTURA_LINE_CODE: string = process.env.REACT_APP_PINTURA_LINE_CODE!;
export const STATUS_PROFORM_SEND_PRODUCTION_CODE: string = process.env.REACT_APP_STATUS_PROFORM_SEND_PRODUCTION_CODE!;
export const STATUS_PROFORM_APPROVED_PRODUCTION_CODE: string = process.env.REACT_APP_STATUS_PROFORM_APPROVED_PRODUCTION_CODE!;
export const STATUS_PROFORM_SEND_GERENCIA_COMERCIAL_CODE: string = process.env.REACT_APP_STATUS_PROFORM_SEND_GERENCIA_COMERCIAL_CODE!;
export const STATUS_PROFORM_APPROVED_GERENCIA_COMERCIAL_CODE: string = process.env.REACT_APP_STATUS_PROFORM_APPROVED_GERENCIA_COMERCIAL_CODE!;
export const STATUS_PROFORM_ISSUED_CODE: string = process.env.REACT_APP_STATUS_PROFORM_ISSUED_CODE!;
export const STATUS_PROFORM_PENDING_CODE: string = process.env.REACT_APP_STATUS_PROFORM_PENDING_CODE!;
export const STATUS_PROJECT_CODE: string = process.env.REACT_APP_STATUS_PROJECT_CODE!;
export const TYPE_USER_EJECUTIVA_CODE: string = process.env.REACT_APP_TYPE_USER_EJECUTIVA_CODE!;
export const TYPE_USER_GERENTE_CODE: string = process.env.REACT_APP_TYPE_USER_GERENTE_CODE!;
export const TYPE_USER_ADMINISTRADOR_CODE: string = process.env.REACT_APP_TYPE_USER_ADMINISTRADOR_CODE!;
export const TYPE_USER_SUPERVISOR_CODE: string = process.env.REACT_APP_TYPE_USER_SUPERVISOR_CODE!;
export const PROFILE_TYPES_CODE: string = process.env.REACT_APP_PROFILE_TYPES_CODE!;
export const PROFILE_TYPE_ADMINISTRATOR_CODE: string = process.env.REACT_APP_PROFILE_TYPE_ADMINISTRATOR_CODE!;
export const NAVBAR_HEIGHT: string = process.env.REACT_APP_NAVBAR_HEIGHT!;
export const NAVBARSUB_HEIGHT: string = process.env.REACT_APP_NAVBARSUB_HEIGHT!;
export const HEIGHT_TABLE_CONTAINER: string = process.env.REACT_APP_HEIGHT_TABLE_CONTAINER!;
export const NAVBAR_HEIGHT_SUM: string = process.env.REACT_APP_NAVBAR_HEIGHT_SUM!;
export const CLIENT_TYPES_CODE: string = process.env.REACT_APP_CLIENT_TYPES_CODE!;
export const CLIENT_TYPE_LEGAL_CODE: string = process.env.REACT_APP_CLIENT_TYPE_LEGAL_CODE!;
export const CLIENT_TYPE_LEGAL_VALUE: string = process.env.REACT_APP_CLIENT_TYPE_LEGAL_VALUE!;
export const CLIENT_TYPE_NATURAL_CODE: string = process.env.REACT_APP_CLIENT_TYPE_NATURAL_CODE!;
export const CLIENT_TYPE_NATURAL_VALUE: string = process.env.REACT_APP_CLIENT_TYPE_NATURAL_VALUE!;
export const COLOR_PRIMARY: string = process.env.REACT_APP_COLOR_PRIMARY!;
export const COLOR_SECONDARY: string = process.env.REACT_APP_COLOR_SECONDARY!;
export const CATALOGUE_AREA_USER_CODE: string = process.env.REACT_APP_CATALOGUE_AREA_USER_CODE!;
export const CATALOGUE_TYPE_USER_CODE: string = process.env.REACT_APP_CATALOGUE_TYPE_USER_CODE!;
export const CLIENTS_POSITIONS_CODE: string = process.env.REACT_APP_CLIENTS_POSITIONS_CODE!;
export const CLIENTS_AREAS_CODE: string = process.env.REACT_APP_CLIENTS_AREAS_CODE!;
export const URL_BACKEND: string = process.env.REACT_APP_URL_BACKEND!;
export const MAIL_HOST_CODE: string = process.env.REACT_APP_MAIL_HOST_CODE!;
export const MAIL_PORT_CODE: string = process.env.REACT_APP_MAIL_PORT_CODE!;
export const MAIL_NOTIFICATION_USER_CODE: string = process.env.REACT_APP_MAIL_NOTIFICATION_USER_CODE!;
export const MAIL_NOTIFICATION_PASS_CODE: string = process.env.REACT_APP_MAIL_NOTIFICATION_PASS_CODE!;
export const MAIL_RESET_PASSWORD_USER_CODE: string = process.env.REACT_APP_MAIL_RESET_PASSWORD_USER_CODE!;
export const MAIL_RESET_PASSWORD_PASS_CODE: string = process.env.REACT_APP_MAIL_RESET_PASSWORD_PASS_CODE!;
export const COMPANY_CODE: string = process.env.REACT_APP_COMPANY_CODE!;
export const COMPANY_RUC_CODE: string = process.env.REACT_APP_COMPANY_RUC_CODE!;
export const COMPANY_ADDRESS_CODE: string = process.env.REACT_APP_COMPANY_ADDRESS_CODE!;
export const COMPANY_PHONE_CODE: string = process.env.REACT_APP_COMPANY_PHONE_CODE!;
export const COMPANY_WEB_PAGE_CODE: string = process.env.REACT_APP_COMPANY_WEB_PAGE_CODE!;
export const IDENTIFICATION_TYPE_CEDULA_CODE: string = process.env.REACT_APP_IDENTIFICATION_TYPE_CEDULA_CODE!; 
export const LUNCH_OPTION_TYPES_CODE: string = process.env.REACT_APP_LUNCH_OPTION_TYPES_CODE!;
export const LUNCH_OPTION_TYPE_SOUP_CODE: string = process.env.REACT_APP_LUNCH_OPTION_TYPE_SOUP_CODE!;
export const LUNCH_OPTION_TYPE_RICE_CODE: string = process.env.REACT_APP_LUNCH_OPTION_TYPE_RICE_CODE!;
export const LUNCH_OPTION_TYPE_JUICE_CODE: string = process.env.REACT_APP_LUNCH_OPTION_TYPE_JUICE_CODE!;
export const LUNCH_OPTION_TYPE_PROTEIN_CODE: string = process.env.REACT_APP_LUNCH_OPTION_TYPE_PROTEIN_CODE!;
export const LUNCH_OPTION_TYPE_ADICIONAL_CODE: string = process.env.REACT_APP_LUNCH_OPTION_TYPE_ADICIONAL_CODE!;
export const LUNCH_VALUES_PARAMETERS_CODE: string = process.env.REACT_APP_LUNCH_VALUES_PARAMETERS_CODE!;
export const LUNCH_VALUE_CG_CODE: string = process.env.REACT_APP_LUNCH_VALUE_CG_CODE!;
export const LUNCH_VALUE_USER_CODE: string = process.env.REACT_APP_LUNCH_VALUE_USER_CODE!;
export const LUNCH_VALUE_TOTAL_CODE: string = process.env.REACT_APP_LUNCH_VALUE_TOTAL_CODE!;
export const CATALOGUE_EXCEL_MASSIVE_TYPES_CODE: string = process.env.REACT_APP_CATALOGUE_EXCEL_MASSIVE_TYPES_CODE!;
export const TASK_ALMUERZOS_FILTRAR_CODE: string = process.env.REACT_APP_TASK_ALMUERZOS_FILTRAR_CODE!;
export const LUNCH_TICKET_IN_PROGRESS_CODE: string = process.env.REACT_APP_LUNCH_TICKET_IN_PROGRESS_CODE!;
export const WHATSAPP_QR_CODE: string = process.env.REACT_APP_WHATSAPP_QR_CODE!;
export const WHATSAPP_PARAMETERS_CODE: string = process.env.REACT_APP_WHATSAPP_PARAMETERS_CODE!;
export const STATUSES_LUNCH_TICKET_CODE: string = process.env.REACT_APP_STATUSES_LUNCH_TICKET_CODE!;
export const LUNCH_TICKET_TO_GO_CODE: string = process.env.REACT_APP_LUNCH_TICKET_TO_GO_CODE!;
export const LUNCH_TICKET_ISSUED_CODE: string = process.env.REACT_APP_LUNCH_TICKET_ISSUED_CODE!;
export const LUNCH_TICKET_SERVED_CODE: string = process.env.REACT_APP_LUNCH_TICKET_SERVED_CODE!;
export const STATUS_ELEMENT_WAREHOUSE_CODE: string = process.env.REACT_APP_STATUS_ELEMENT_WAREHOUSE_CODE!;
export const SUPPLIER_TYPE_FOOD_CODE: string = process.env.REACT_APP_SUPPLIER_TYPE_FOOD_CODE!;
export const SUPPLIER_TYPES_CODE: string = process.env.REACT_APP_SUPPLIER_TYPES_CODE!;