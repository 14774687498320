import { useQuery } from "@tanstack/react-query";
import { lunchApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsLunchI,
    LunchI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getLunchs = async ({
    pagination, 
    sorting, 
    globalFilter,
    range,
    rangeVal,
    supplier
}:PropsLunchI):Promise<ServiceResponseI<DataI<LunchI>>> => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));
        rangeVal && params.append('range', JSON.stringify(range));
        supplier && params.append('supplier', supplier.toString());
        //headers: { 'Authorization': validateToken
        const { data } = await lunchApi.get<ServiceResponseI<DataI<LunchI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
        
    }

    
}

export const getAllLunchs = async ():Promise<ServiceResponseI<LunchI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await lunchApi.get<ServiceResponseI<LunchI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getLunchbyDate = async (date:string):Promise<ServiceResponseI<LunchI> > =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();
        params.append('date', date.toString());

        const { data } = await lunchApi.get<ServiceResponseI<LunchI>>(`/by-date`,{ params, headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllRequestByLunchId = async (lunch:number):Promise<ServiceResponseI<any> > =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await lunchApi.get<ServiceResponseI<any>>(`/all-request-by-lunch-id/${lunch}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const lunchPost = async<T>(getForms: T): Promise<ServiceResponseI<string>  > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lunchApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const lunchPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>  > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lunchApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const lunchById = async (id: number): Promise<ServiceResponseI<LunchI>  > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lunchApi.get<ServiceResponseI<LunchI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const lunchDelete = async (id: number): Promise<ServiceResponseI<string>  > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lunchApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useLunchs = ({
    sorting,
    pagination,
    globalFilter,
    range,
    rangeVal,
    supplier
}:PropsLunchI)=>{

    
    const lunchsQuery = useQuery(
        ["lunchs", { pagination, sorting, globalFilter, range, rangeVal, supplier }],
        ()=>getLunchs({ pagination, sorting, globalFilter, range, rangeVal, supplier }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        lunchsQuery,
    };
}