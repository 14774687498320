import { useQuery } from "@tanstack/react-query";
import { lineGroupPresentationApi } from "./axios";
import {
    ServiceResponseI,
    PresentationI,
    LineGroupPresentationI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


export const LGPGetAllPresentationsbyLineIdGroupId = async (line:number, group:number):Promise<ServiceResponseI<PresentationI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('line', line.toString());
        params.append('group', group.toString());

        const { data } = await lineGroupPresentationApi.get<ServiceResponseI<PresentationI[]>>(`/all-presentations-by-line-id-group-id/`, {params, headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const getAllbyLineId = async (id:number):Promise<ServiceResponseI<LineGroupPresentationI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await lineGroupPresentationApi.get<ServiceResponseI<LineGroupPresentationI[]>>(`/all-presentation-by-line-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const lineGroupPresentationAssignPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lineGroupPresentationApi.post<ServiceResponseI<string>>("/assign-line-group-to-presentation", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}



export const lineGroupPresentationPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lineGroupPresentationApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const lineGroupPresentationPut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lineGroupPresentationApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const lineGroupPresentationbyId = async (id: number): Promise<ServiceResponseI<LineGroupPresentationI[]>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await lineGroupPresentationApi.get<ServiceResponseI<LineGroupPresentationI[]>>(`/all-by-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

