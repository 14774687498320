import { useQuery } from "@tanstack/react-query";
import { bankApi } from "./axios";
import { 
    DataI,
    ServiceResponseI,
    PropsBankI,
    BankI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';


const getBanks = async ({
    pagination, 
    sorting, 
    globalFilter,
}:PropsBankI):Promise<ServiceResponseI<DataI<BankI>>>  => {

    try{ 
        
        const validateToken = ValidateCurrentToken();

        if(!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        //headers: { 'Authorization': validateToken
        const { data } = await bankApi.get<ServiceResponseI<DataI<BankI>>>('',{ params, headers: { 'Authorization': validateToken}});
        return data;
    
    }catch(err){
        console.log(err);
        const obj = (err as AxiosError).response;

        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
        
    }

    
}

export const getAllBanks = async ():Promise<ServiceResponseI<BankI[]>> =>{
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await bankApi.get<ServiceResponseI<BankI[]>>(`/all-no-pagination`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const bankPost = async<T>(getForms: T): Promise<ServiceResponseI<string> > => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await bankApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const bankPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await bankApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const bankById = async (id: number): Promise<ServiceResponseI<BankI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await bankApi.get<ServiceResponseI<BankI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const bankDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await bankApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const useBanks = ({
    sorting,
    pagination,
    globalFilter,
}:PropsBankI)=>{

    
    const banksQuery = useQuery(
        ["banks", { pagination, sorting, globalFilter }],
        ()=>getBanks({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus:false,
            keepPreviousData:true
        }
    );

    return {
        banksQuery,
    };
}