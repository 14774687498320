import React, { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from '@mui/material';
import {
    Key as KeyIcon,
    Person as PersonIcon,
    Check as CheckIcon,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { usersLoginPost } from "../../libs/apis";
import { UsersLoginI } from "../../libs/interfaces";
import { useRouterStore } from "../../libs/stores";
import CenefaLogin from "../images/cenefa-cg.png"; 
import FondoLogin from "../images/fondo-min.jpg";
import '../css/login.css';
import { useFormik } from "formik";
import * as Yup from 'yup';



export const Login = () => {
    const navigate = useNavigate();
    const tokens = localStorage.getItem(process.env.REACT_APP_TOKEN!);
    const setModules = useRouterStore((state) => state.setModules);
    const setProfileId = useRouterStore((state) => state.setProfileId);
    const setClientId = useRouterStore((state) => state.setClientId);
    const setAvatar = useRouterStore((state) => state.setAvatar);
    const [isProcessing, setProcessing] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [typeInput, setTypeInput] = useState("password");



    const formik = useFormik({
        initialValues:{
            user:'',
            password:''
        },
        validationSchema: Yup.object({
            user:Yup.string()
            .trim()
            .min(3, 'Too Short!')
            .max(30, 'Too Long!')
            .required('Required'),
            password:Yup.string()
            .trim()
            .min(5, 'Too Short!')
            .max(40, 'Too Long!')
            .required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                setProcessing(true);
                const { data:dataUser } = await usersLoginPost(values);
                
                if(!dataUser) return;
    
                localStorage.setItem(process.env.REACT_APP_TOKEN!, JSON.stringify(dataUser));
                enqueueSnackbar(`Bienvenido ${dataUser.user.fullName}`, { variant: 'success' });
                setModules(dataUser.user.modules);
                setProfileId(dataUser.user.profileId); 
                setClientId(dataUser.user.clientId!); 
                setAvatar(dataUser.user.avatar!); 
                setProcessing(false);
                formik.resetForm();
                setTimeout(() => {
                    navigate('/', { replace: true });
                }, 1000);
            } catch (err: any) { 
                console.log(err)
                setProcessing(false);
                enqueueSnackbar(err ?? 'Error, comuníquese con Sistemas', { variant: 'error' });
            }
        },
    })

    if (tokens) {
        return <Navigate to='/' replace />;
    }


    return (
        <form onSubmit={formik.handleSubmit}>
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="login-root"
            style={{
                backgroundImage: `url(${FondoLogin})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        >
            <Grid item xl={3} md={4} sm={6} xs={12} >
                <Paper elevation={3}>
                    <Grid container justifyContent='center' className="login-sub-root">
                        <Grid item xs={12} className="login-cenefa">
                            <img src={CenefaLogin} width='100.12%' height='100%' alt='CG' />
                        </Grid>
                        <Grid item md={8} sm={10} xs={11} className="login-texfield">
                            <TextField
                                label='Usuario'
                                autoFocus
                                variant='outlined'
                                id='user'
                                name='user'
                                fullWidth
                                value={formik.values.user}
                                onChange={formik.handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <PersonIcon />
                                        </InputAdornment>
                                    )
                                }}
                                error={formik.touched.user && Boolean(formik.errors.user)}
                                helperText={formik.touched.user && formik.errors.user}
                            />
                        </Grid>
                        <Grid item md={8} sm={10} xs={11} className="login-texfield">
                            <TextField
                                label='Password'
                                variant='outlined'
                                id='password'
                                name='password'
                                fullWidth
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                type={typeInput}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <KeyIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton 
                                                size="small"
                                                color="secondary"
                                                onClick={(e)=>{
                                                    if(typeInput === "password"){
                                                        setTypeInput("text")
                                                    }else{
                                                        setTypeInput("password")
                                                    }
                                                }}
                                                >
                                                {typeInput === "password" ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item sm={6} xs={8} className="login-button">
                            <Button
                                fullWidth
                                size='large'
                                type="submit"
                                variant="contained"
                                disabled={isProcessing}
                                endIcon={isProcessing ? <CircularProgress size={20}/> : <CheckIcon/>}
                                >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        </form>
    );
}