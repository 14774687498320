import {
  Paper,
  Grid
} from '@mui/material';
import {
  NAVBARSUB_HEIGHT
} from "../../constants"

interface Props {
  children: JSX.Element;
}


export const NavbarSub = (props: Props) => {

  return (
    <Paper
      square
      sx={{
        height:`${NAVBARSUB_HEIGHT}px`,
        maxHeight: `${NAVBARSUB_HEIGHT}px`,
        padding: "4px 3px 3px 3px",
        position:"relative",
        left:-1
      }}
      variant="outlined">

      {props.children}
    </Paper>
  );
}