import { useQuery } from "@tanstack/react-query";
import { supplierTypeApi } from "./axios";
import {
    DataI,
    PropsI,
    ServiceResponseI,
    SupplierTypeI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem,
} from '../../helpers'
import { AxiosError } from 'axios';

const getAllSuppliersType = async ({
    pagination,
    sorting,
    globalFilter
}: PropsI): Promise<ServiceResponseI<DataI<SupplierTypeI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));

        //headers: { 'Authorization': validateToken
        const { data } = await supplierTypeApi.get<ServiceResponseI<DataI<SupplierTypeI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        
    }


}

export const getAllSuppliersTypeNoPagination = async (): Promise<ServiceResponseI<SupplierTypeI[]>> => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await supplierTypeApi.get<ServiceResponseI<SupplierTypeI[]>>("/all-no-pagination", { headers: { 'Authorization': validateToken } });

        return data

    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}


export const suppliersTypePost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierTypeApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const suppliersTypePut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierTypeApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const supplierTypeDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierTypeApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const supplierTypeById = async (id: number): Promise<ServiceResponseI<SupplierTypeI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await supplierTypeApi.get<ServiceResponseI<SupplierTypeI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const useSuppliersType = ({
    sorting,
    pagination,
    globalFilter,
}: PropsI) => {


    const supplierTypeQuery = useQuery(
        ["suppliersType", { pagination, sorting, globalFilter }],
        () => getAllSuppliersType({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );






    return {
        supplierTypeQuery,
    };
}