import React from "react";
import {
  Navigate,
} from "react-router-dom";

const QuoterAdd = React.lazy(() => import("../../../components/cotizador/components/add/page"));
const QuoterList = React.lazy(() => import("../../../components/cotizador/components/list/components/page"));

export const quoterRoute = [
  {
    path: 'list',
    element: <QuoterList />,
  },
  {
    path: 'add',
    element: <QuoterAdd />,
  },
  {
    path: 'edit',
    element: <Navigate to="/quoter/add" />
  },
  {
    path: 'edit/:id',
    element: <QuoterAdd />
  },
  {
    path: '',
    element: <Navigate replace to="/quoter/list" />
  },
]