import React from "react";
import {
    Navigate,
} from "react-router-dom";

const PresentationListComponent = React.lazy(() => import("../../../../../components/presentations/components/list/page"));
const PresentationAddComponent = React.lazy(() => import("../../../../../components/presentations/components/add/page"));

export const presentationRoute = [
    {
        path: 'list',
        element: <PresentationListComponent />
    },
    {
        path: 'add',
        element: <PresentationAddComponent />
    },
    {
        path: 'edit',
        element: <Navigate to="/models/presentations/add" />
    },
    {
        path: 'edit/:id',
        element: <PresentationAddComponent />
    },
    {
        path: '',
        element: <Navigate replace to="/models/presentations/list" />
    },
];