import { styled } from '@mui/material/styles';
import {
  NAVBAR_HEIGHT
} from "../../../libs/constants";


export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: 0,
      marginTop:`57px`,
      display:'flex',
      position:"relative",
      overflowY:"hidden",
      height:`calc(100vh - 57px)`,
      minHeight:`calc(100vh - 57px)`,
      maxHeight:`calc(100vh - 57px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: '0px',
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
);