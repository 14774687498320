import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { proformChangeStatusPost } from "../../libs/apis/proforms";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";

export const ProformCancel = () => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        handleValidate();
    }, []);

    const handleValidate = async () => {
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: 'Motivo de Rechazo',
            inputPlaceholder: '...',
            confirmButtonColor: '#0E3B5F',
            inputAttributes: {
                'aria-label': 'Type your issue here'
            },
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Es necesario poner un Motivo de Rechazo!'
                }
                return null;
                
            }
        })

        if (text) {
            try {
                const { data } = await proformChangeStatusPost({ path: "cancel", id: Number(id!), message: text });
                Swal.fire({
                    icon: 'warning',
                    title: `Proforma N°:${id!.toString().padStart(6, '0')}`,
                    confirmButtonColor: '#0E3B5F',
                    html: `<strong>${data}</strong>`
                }).then(() => {
                    window.close();

                })
            } catch (err: any) {
                console.log(err);
                enqueueSnackbar(err.toString(), { variant: 'error' });
                setTimeout(()=>{
                    window.close();
                },2000)
            }
        }else{
            window.close();
        }

    };

    return (
        <CircularProgress size={30} />
    )
}