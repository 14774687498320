import { useQuery } from "@tanstack/react-query";
import { clientContactBrandApi } from "./axios";
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { ClientContactBrandI, ServiceResponseI } from "../../interfaces";
import { AxiosError } from "axios";

export const getAllBrandsByClientContactId = async (id:number) => {
    try{ 
        const validateToken = ValidateCurrentToken();
        if(!validateToken) throw new Error('TOKEN INVALIDO');


        const { data } = await clientContactBrandApi.get<ServiceResponseI<ClientContactBrandI[]>>(`/all-brands-client-contact-id/${id}`,{ headers: { 'Authorization': validateToken}});
        
        return data;

    }catch(err){
        const obj = (err as AxiosError).response;
        if(obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
    
}

