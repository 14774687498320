import React from "react";
import { Box, Backdrop } from "@mui/material"
import loadingPicture from "../images/animacion-logo.gif"

export const LoadingPage = () => {
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "grid",
                placeContent: "center",
                background:"rgba(0, 0, 0, 0.5)",
            }}>
                <img src={loadingPicture} width={500} />
        </Box>
    )
}