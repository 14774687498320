import React from "react";
import { Box } from '@mui/material';
import Drawer from '@mui/material/Drawer';

// type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
    open: boolean;
    children?: JSX.Element;
    handleClose: () => void;
    width?: number;
}

export const DrawerRightBack = ({ 
    open, 
    handleClose, 
    children, 
    width = 300
}: Props) => {


    return (

        <Drawer
            anchor='right'
            open={open}
            onClose={handleClose}
            sx={{
                zIndex:1200
            }}
        >
            <Box
                sx={{ width, p:2 }}
                role="presentation">
                {children}
            </Box>
        </Drawer>


    );
}