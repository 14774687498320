import React from "react";
import {
  Navigate,
} from "react-router-dom";

const SupplierAdd = React.lazy(() => import("../../../../../components/supplier/components/add/page"));
const SupplierList = React.lazy(() => import("../../../../../components/supplier/components/list/page"));

export const suppliersRoute = [
  {
    path: 'list',
    element: <SupplierList />,
  },
  {
    path: 'add',
    element: <SupplierAdd />,
  },
  {
    path: 'edit',
    element: <Navigate to="/store/suppliers/add" />
  },
  {
    path: 'edit/:id',
    element: <SupplierAdd />
  },
  {
    path: '',
    element: <Navigate replace to="/store/suppliers/list" />
  },
]