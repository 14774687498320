import { useQuery } from "@tanstack/react-query";
import { presentationApi } from "./axios";
import {
    DataI,
    ServiceResponseI,
    PresentationI,
    PropsGroupsI,
    GetLineGroupPresentationParamsI,
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem,
} from '../../helpers';
import { AxiosError } from 'axios';

const getAllPresentations = async ({
    pagination,
    sorting,
    globalFilter,
    line,
    group
}: GetLineGroupPresentationParamsI): Promise<ServiceResponseI<DataI<PresentationI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        globalFilter && params.append('search', globalFilter);
        sorting && params.append('sorting', JSON.stringify(sorting));
        line && params.append('line', JSON.stringify(line));
        group && params.append('group', JSON.stringify(group));

        //headers: { 'Authorization': validateToken
        const { data } = await presentationApi.get<ServiceResponseI<DataI<PresentationI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        
    }


}

export const getAllPresentationsNoPagination = async () => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await presentationApi.get("/all-no-pagination", { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
    }
}

export const getAllPresentationsParentNoPagination = async () => {

    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const { data } = await presentationApi.get("/all-parents-no-pagination", { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
    }
}


export const presentationPost = async<T>(getForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await presentationApi.post<ServiceResponseI<string>>("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const presentationPut = async<T>(id: number, updateForms: T): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await presentationApi.put<ServiceResponseI<string>>(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const presentationDelete = async (id: number): Promise<ServiceResponseI<string>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await presentationApi.delete<ServiceResponseI<string>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const presentationById = async (id: number): Promise<ServiceResponseI<PresentationI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await presentationApi.get<ServiceResponseI<PresentationI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const usePresentations = ({
    sorting,
    pagination,
    globalFilter,
    line,
    group,
}: GetLineGroupPresentationParamsI) => {


    const presentationQuery = useQuery(
        ["presentation", { pagination, sorting, globalFilter, line, group }],
        () => getAllPresentations({ pagination, sorting, globalFilter, line, group }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );






    return {
        presentationQuery,
    };
}