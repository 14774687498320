import { useQuery } from "@tanstack/react-query";
import { quoterApi } from "./axios";
import {
    DataI,
    PropsI,
    ServiceResponseI,
    QuoterI,
    ListQuoterI
} from '../../interfaces';
import {
    ValidateCurrentToken,
    LogoutSystem, 
} from '../../helpers';
import { AxiosError } from 'axios';



const getQuoters = async ({
    pagination,
    sorting,
    globalFilter
}: PropsI): Promise<ServiceResponseI<DataI<ListQuoterI>>> => {

    try {

        const validateToken = ValidateCurrentToken();

        if (!validateToken) throw new Error('TOKEN INVALIDO');

        const params = new URLSearchParams();

        params.append('page', (pagination!.pageIndex + 1).toString());
        params.append('limit', pagination!.pageSize.toString());
        params.append('search', globalFilter ?? '');
        params.append('sorting', JSON.stringify(sorting ?? []));

        //headers: { 'Authorization': validateToken
        const { data } = await quoterApi.get<ServiceResponseI<DataI<ListQuoterI>>>('', { params, headers: { 'Authorization': validateToken } });
        return data;

    } catch (err) {
        const obj = (err as AxiosError).response;

        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }

        throw new Error((obj?.data as Error).message)
    }


}

export const quoterPost = async<T>(getForms: T): Promise<number> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await quoterApi.post("", getForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const quoterPut = async<T>(id: number, updateForms: T): Promise<string> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await quoterApi.put(`/${id}`, updateForms, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        console.log(err);
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const quoterById = async (id: number): Promise<ServiceResponseI<QuoterI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await quoterApi.get<ServiceResponseI<QuoterI>>(`/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

export const quoterByProformId = async (id: number): Promise<ServiceResponseI<QuoterI>> => {
    try {
        const validateToken = ValidateCurrentToken();
        if (!validateToken) throw new Error('TOKEN INVALIDO');
        const { data } = await quoterApi.get<ServiceResponseI<QuoterI>>(`/by-proform-id/${id}`, { headers: { 'Authorization': validateToken } });
        return data;
    } catch (err) {
        const obj = (err as AxiosError).response;
        if (obj?.status === 401) {
            console.error(obj.statusText);
            LogoutSystem();
        }
        throw new Error((obj?.data as Error).message)
    }
}

// export const createPdfById = async (id: number): Promise<ServiceResponseI<string>> => {
//     try {
//         const validateToken = ValidateCurrentToken();
//         if (!validateToken) throw new Error('TOKEN INVALIDO');
//         const { data } = await quoterApi.get<ServiceResponseI<string>>(`/create-pdf/${id}`, { headers: { 'Authorization': validateToken } });
//         return data;
//     } catch (err) {
//         const obj = (err as AxiosError).response;
//         if (obj?.status === 401) {
//             console.error(obj.statusText);
//             LogoutSystem();
//         }
//         throw new Error((obj?.data as Error).message)
//     }
// }

// export const changeStatusPost = async ({ path, id, message }: { path: string, id: number, message:string }): Promise<ServiceResponseI<string>> => {
//     try {
//         const validateToken = ValidateCurrentToken();
//         if (!validateToken) throw new Error('TOKEN INVALIDO');
//         const { data } = await quoterApi.post<ServiceResponseI<string>>(`${path}/${id}`, {message}, { headers: { 'Authorization': validateToken } });
//         return data;
//     } catch (err) {
//         console.log(err);
//         const obj = (err as AxiosError).response;
//         if (obj?.status === 401) {
//             console.error(obj.statusText);
//             LogoutSystem();
//         }
//         throw new Error((obj?.data as Error).message)
//     }
// }

// export const changeStatusGet = async ({ path, id }: { path: string, id: number }): Promise<ServiceResponseI<string>> => {
//     try {
//         const validateToken = ValidateCurrentToken();
//         if (!validateToken) throw new Error('TOKEN INVALIDO');
//         const { data } = await quoterApi.get<ServiceResponseI<string>>(`/${path}/${id}`, { headers: { 'Authorization': validateToken } });
//         return data;
//     } catch (err) {
//         const obj = (err as AxiosError).response;
//         if (obj?.status === 401) {
//             console.error(obj.statusText);
//             LogoutSystem();
//         }
//         throw new Error((obj?.data as Error).message)
//     }
// }




export const useQuoters = ({
    sorting,
    pagination,
    globalFilter
}: PropsI) => {


    const quoterQuery = useQuery(
        ["quoters", { pagination, sorting, globalFilter }],
        () => getQuoters({ pagination, sorting, globalFilter }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    );






    return {
        quoterQuery,
    };
}