import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface PropsI {
    children:any;
    onClose:()=>void;
    bg?:string;
}
export function BootstrapDialogTitle(props:PropsI) {
    const { children, onClose, bg, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, background :bg ?? "white", height:70, maxHeight:70 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "red",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};